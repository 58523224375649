<template>
  <div>
    <kk-container
      ref="KKContainer"
      :loadMoreStatus="loadMoreStatus"
      :loadMoreMarginTop="2"
      :isShowEmpty="isShowEmpty"
      :isShowLoading="isShowLoading"
      :isBookCorrectEmpty="true"
      :emptyTitle="emptyTitle"
      :emptyTopOffset="140"
      @scrollToBottom="scrollToBottom"
    >
      <template slot="header">
        <div @click="!isCanSearch() && searchClick()">
          <search
            class="search-bar"
            bgColor="#F3F4F5"
            :isShowSuffix="true"
            placeholder="搜索商品名称"
            :maxlength="50"
            @focus="focus"
            @onBlur="onBlur"
            @search="toSearchProdPage"
            :searchValue.sync="searchValue"
          >
            <div
              @click.stop="toSearchProdPage"
              class="search-action"
              v-if="isFocus && isCanSearch()"
            >
              {{ "搜索" }}
            </div>
          </search>
        </div>

        <tabs
          :tabs="firstClassifyList"
          :style="tabStyle"
          :selectIndex="selectTabIndex"
          @clickTab="clickTab"
        ></tabs>
      </template>
      <template slot="main">
        <div ref="KKContainerMain">
          <prod-info
            v-if="prodList && prodList.length > 0"
            :prodList="prodList"
          ></prod-info>
          <classify-select
            v-if="showClassifySelect"
            :classifyList="classifyList()"
            :classifyId="curretnSelectClassifyId()"
            :style="
              'position:fixed;min-height:100vh;bottom:0px;' +
              'top:' +
              headHeight()
            "
            @clickShadow="clickShadow"
            @clickClassifySelect="clickClassifySelect"
          ></classify-select>
          <wx-inner-config v-if="isInWeixin"></wx-inner-config>
        </div>
      </template>
    </kk-container>
  </div>
</template>

<script>
import search from "@/components/search/search";
import Tabs from "./components/tabs.vue";
import ClassifySelect from "./components/classifySelect.vue";
import ProdInfo from "./components/prodInfo.vue";
import KkContainer from "@/components/KkContainer";
import WxInnerConfig from "@/components/wxInnerConfig";
import { isWeixin, isIOS } from "@/utils/utils";
import { getCorrigendumCategorys, getCorrigendumByCategory } from "@/api/book";
import { Toast } from "vant";
var wx = require("weixin-js-sdk");
export default {
  components: {
    search,
    Tabs,
    ClassifySelect,
    ProdInfo,
    KkContainer,
    WxInnerConfig,
  },
  data() {
    return {
      current: 1,
      pages: 0,
      loadMoreStatus: "",
      isShowEmpty: false,
      emptyTitle: "请您别着急噢，我们正在马不停蹄添加中～",
      isShowLoading: false,
      active: 0,
      showClassifySelect: false,
      selectTabIndex: 0,
      requestCorrectInfoFinished: true,
      selectCategoryId: "",
      prodList: [],
      firstClassifyList: [],
      secondClassifyIds: [],
      secondClassify: {},
      searchValue: "",
      isFocus: false,
    };
  },
  computed: {
    isInWeixin() {
      return isWeixin();
    },
    tabStyle() {
      if (this.firstClassifyList.length > 0) {
        return "background-color: #ffffff;";
      }
      return "background-color: #f5f6f7;";
    },
  },
  mounted() {
    this.getCategoryList();
    if (isWeixin()) {
      wx.ready(function () {
        const title = "库课图书_勘误汇总";
        const desc = "库课图书_勘误汇总";
        const imgUrl = "https://oss.kuke99.com/img/book/share/logo.png";
        //// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
        wx.updateTimelineShareData({
          title: title, // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {},
        });
        wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc || "", // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {},
        });
      });
      wx.error(function () {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    }
  },
  methods: {
    /**
     * 是否需要在当前页面进行搜索
     */
    isCanSearch() {
      // iOS搜索逻辑在当天页面弹出搜索框
      return isIOS();
    },
    /**
     * 获取勘误分类列表
     */
    async getCategoryList() {
      let data = await getCorrigendumCategorys();
      if (data) {
        this.getFirstCategoryDataSource(data);
      } else {
        this.isShowEmpty = true;
      }
    },

    /**
     * 获取tabBar一级分类数据源
     */
    getFirstCategoryDataSource(data) {
      this.firstClassifyList = [];
      this.secondClassify = {};
      this.secondClassifyIds = [];
      for (let item of data) {
        item.isShowClassify = false;
        if (item.categories && item.categories.length > 0) {
          item.isMore = true;
        } else {
          item.isMore = false;
        }
        this.firstClassifyList.push(item);
        let secondCategoryArr = [];
        let obj = {
          categoryId: item.categoryId,
          parentId: item.parentId,
          categoryName: item.categoryName,
          status: item.status,
          categories: item.categories,
          isShowStatus: item.isShowStatus,
        };
        secondCategoryArr.push(obj);
        if (item.categories && item.categories.length > 0) {
          secondCategoryArr = secondCategoryArr.concat(
            this.recursionSecondCategoryList(item.categories)
          );
        }
        this.secondClassifyIds.push(item.categoryId || "");
        this.secondClassify[`${item.categoryId}`] = secondCategoryArr;
      }
      if (this.firstClassifyList.length > 0) {
        this.selectCategoryId = this.firstClassifyList[0].categoryId;
        this.getCorrectList(true);
      } else {
        this.isShowEmpty = true;
      }
    },
    /**
     * 递归获取二级分类目录
     */
    recursionSecondCategoryList(list) {
      const arr = [];
      let obj = {};
      list.forEach((item) => {
        const tmp = { ...item };
        if (tmp.categories) {
          obj = {
            categoryId: tmp.categoryId,
            parentId: tmp.parentId,
            categoryName: tmp.categoryName,
            status: tmp.status,
            categories: tmp.categories,
            isShowStatus: tmp.isShowStatus,
          };
          arr.push(obj);
        } else {
          obj = {
            categoryId: tmp.categoryId,
            parentId: tmp.parentId,
            categoryName: tmp.categoryName,
            status: tmp.status,
            categories: tmp.categories,
            isShowStatus: tmp.isShowStatus,
          };
          arr.push(obj);
        }
      });
      return arr;
    },
    /**
     * 获取勘误列表
     */
    async getCorrectList(isLoading) {
      if (isLoading) {
        this.loadMoreStatus = "";
        this.isShowLoading = true;
      }
      this.isShowEmpty = false;
      let param = {
        categoryId: this.selectCategoryId || "",
        current: this.current,
        size: 10,
      };
      this.requestCorrectInfoFinished = false;
      let result = await getCorrigendumByCategory(param);
      this.isShowLoading = false;
      this.requestCorrectInfoFinished = true;
      if (result) {
        this.current++;
        this.pages = result.pages;
        this.prodList.push(...result.records);
        if (this.current > this.pages) {
          // 到达底部
          setTimeout(() => {
            let contentHeihgt = window.getComputedStyle(
              this.$refs.KKContainerMain
            ).height;
            if (parseInt(contentHeihgt) > window.screen.height) {
              this.loadMoreStatus = "no-more";
            } else {
              this.loadMoreStatus = "";
            }
          }, 30);
        } else {
          this.loadMoreStatus = "";
        }
      } else {
        this.loadMoreStatus = "";
      }
      if (this.prodList.length == 0) {
        this.isShowEmpty = true;
      }
    },
    /**
     *获取顶部高度
     */
    headHeight() {
      return this.$refs.KKContainer.headHeight();
    },
    /**
     * 跳转搜索页面
     */
    searchClick() {
      if (this.showClassifySelect) {
        this.showClassifySelect = false;
        return;
      }
      this.$router.push("/bookCorrect/search");
    },
    /**
     * 点击tabBar
     */
    clickTab(index) {
      if (this.showClassifySelect) {
        this.showClassifySelect = false;
        this.firstClassifyList[this.selectTabIndex].isShowClassify = false;
        return;
      }
      if (
        index == this.selectTabIndex &&
        this.firstClassifyList[index].isMore
      ) {
        this.showClassifySelect = true;
        this.firstClassifyList[this.selectTabIndex].isShowClassify = true;
      } else {
        this.selectCategoryId = this.firstClassifyList[index].categoryId;
        this.current = 1;
        this.prodList = [];
        window.scroll(0, 0);
        this.getCorrectList(true);
      }
      this.selectTabIndex = index;
    },

    /**
     * 点击阴影区域
     */
    clickShadow() {
      this.showClassifySelect = false;
      this.firstClassifyList[this.selectTabIndex].isShowClassify = false;
    },
    /**
     * 获取分类列表信息
     */
    classifyList() {
      return this.secondClassify[
        `${this.secondClassifyIds[this.selectTabIndex] || ""}`
      ];
    },
    /**
     * 当前选中的分类名称
     */
    curretnSelectClassifyId() {
      return this.firstClassifyList[this.selectTabIndex].categoryId;
    },
    /**
     * 点击分类选择事件
     */
    clickClassifySelect(index) {
      this.showClassifySelect = false;
      let selectClassify = this.classifyList()[index];
      this.firstClassifyList[this.selectTabIndex].categoryName =
        selectClassify.categoryName;
      this.firstClassifyList[this.selectTabIndex].categoryId =
        selectClassify.categoryId;
      this.firstClassifyList[this.selectTabIndex].isShowClassify = false;
      if (this.selectCategoryId !== selectClassify.categoryId) {
        this.selectCategoryId = selectClassify.categoryId;
        this.current = 1;
        this.prodList = [];
        this.getCorrectList(true);
      }
    },
    focus() {
      this.isFocus = true;
    },
    onBlur() {
      setTimeout(() => {
        this.isFocus = false;
      }, 30);
    },
    /**
     * 去搜索页面
     */
    toSearchProdPage() {
      if (this.searchValue && this.searchValue.trim().length > 0) {
        this.$router.push({
          path: "/bookCorrect/search",
          query: { prodName: this.searchValue },
        });
      } else {
        Toast("请先输入搜索词哦");
      }
      this.isFocus = false;
      this.searchValue = "";
    },
    /**
     * 滚动到底部
     */
    scrollToBottom() {
      if (this.current <= this.pages) {
        if (this.requestCorrectInfoFinished) {
          this.loadMoreStatus = "loading";
          this.getCorrectList(false);
        }
      }
    },
  },
};
</script>

<style>
.search-bar {
  width: 100%;
  padding: 20px 28px;
}
.search-action {
  padding-left: 24px;
}
</style>
