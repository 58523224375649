<template>
  <div class="classifySelect" @touchmove.prevent @click="clickShadow">
    <vue-custom-scrollbar class="selectBg">
      <!-- 内容 -->
      <div v-for="(value, index) in classifyList" :key="index">
        <div class="classify-item" @click.stop="clickClassifySelect(index)">
          <div
            :class="
              classifyId === value.categoryId
                ? 'classify-item-titleSelect'
                : 'classify-item-title'
            "
          >
            {{ value.categoryName }}
          </div>
        </div>
        <div class="selectBg-line" v-if="index < classifyList.length - 1"></div>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  components: {
    vueCustomScrollbar,
  },
  props: {
    classifyList: {
      type: Array,
      default: () => [],
    },
    classifyId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 点击阴影区域
     */
    clickShadow() {
      this.$emit("clickShadow");
    },
    /**
     * 点击分类选择事件
     */
    clickClassifySelect(index) {
      this.$emit("clickClassifySelect", index);
    },
  },
};
</script>

<style scoped>
.classifySelect {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.selectBg {
  width: 100%;
  max-height: calc(50vh);
  background: #fff;
  border-radius: 0px 0px 30px 30px;
}
.selectBg-line {
  height: 1px;
  background-color: #f3f3f3;
  margin-left: 56px;
  margin-right: 56px;
}
.classify-item {
  height: 99px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 57px;
  padding-right: 57px;
  overflow: inherit !important;
}
.classify-item-title {
  font-size: 28px;
  color: #333333;
  line-height: 40px;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.classify-item-titleSelect {
  font-size: 28px;
  color: #f01414;
  line-height: 40px;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
