<template>
  <div class="tabs" id="tabs">
    <div class="tabs-item" v-for="(item, index) in tabs" :key="index">
      <div class="tabs-item-content" @click="clickTab(index)">
        <div class="tabs-item-content-space"></div>
        <div
          :class="
            selectIndex == index
              ? 'tabs-item-content-selectTitle'
              : 'tabs-item-content-normalTitle'
          "
        >
          {{ tabName(item.categoryName) }}
        </div>
        <div class="tabs-item-content-space" v-if="!item.isMore"></div>
        <img
          :class="selectImageClass(item)"
          src="@/assets/imgs/bookCorrect/arrow-down-select.png"
          v-if="item.isMore && selectIndex == index"
        />
        <img
          class="tabs-item-content-imgNormal"
          src="@/assets/imgs/bookCorrect/arrow-down-normal.png"
          v-if="item.isMore && selectIndex != index"
        />
        <div style="width: 20px" v-if="index === tabs.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/utils";
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    selectIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabNameMaxLength: 8,
      scrollLeft: 0,
    };
  },
  mounted() {
    if (isMobile()) {
      const stylee = document.createElement("style");
      const sHtml = "::-webkit-scrollbar {display: none;}";
      stylee.innerHTML = sHtml;
      document.getElementsByTagName("head").item(0).appendChild(stylee);
    }
  },
  activated() {
    window.addEventListener("scroll", this.handleScroll, true);
    let parent = document.getElementById("tabs");
    parent.scrollTo(this.scrollLeft, 0);
  },
  deactivated() {
    // 取消监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // 取消监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * 处理滚动事件
     */
    handleScroll() {
      let parent = document.getElementById("tabs");
      if (parent && parent.scrollLeft) {
        this.scrollLeft = parent.scrollLeft;
      }
    },
    /**
     * 选中tab icon
     */
    selectImageClass(item) {
      return item.isShowClassify
        ? "tabs-item-content-imgSelect-up"
        : "tabs-item-content-imgSelect-down";
    },

    /**
     * tab显示的名称
     */
    tabName(name) {
      if (name && name.length > this.tabNameMaxLength) {
        return name.slice(0, this.tabNameMaxLength) + "...";
      }
      return name || "";
    },
    /**
     * 点击Tab
     */
    clickTab(index) {
      this.$emit("clickTab", index);
    },
  },
};
</script>

<style>
.tabs {
  background-color: #fff;
  height: 104px;
  display: flex;
  overflow-x: auto;
}
.tabs-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex-shrink: 0;
}
::-webkit-scrollbar {
  /* display: none; */
}
.tabs-item-content {
  display: flex;
  flex-direction: row;
}

.tabs-item-content-space {
  width: 27px;
}
.tabs-item-content-normalTitle {
  font-size: 28px;
  color: #333333;
  line-height: 40px;
  letter-spacing: 1px;
}
.tabs-item-content-selectTitle {
  font-size: 30px;
  font-weight: 600;
  color: #f01414;
  line-height: 42px;
  letter-spacing: 1px;
}

.tabs-item-content-imgNormal {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  align-self: center;
}
.tabs-item-content-imgSelect-down {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  align-self: center;
}

.tabs-item-content-imgSelect-up {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  transform: rotate(180deg);
  align-self: center;
}
</style>
